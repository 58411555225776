import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './NavBar.module.scss';
import { Link } from 'react-router-dom';
import LoginModal from "../LoginModal/LoginModal.jsx";
import RegisterModal from "../RegisterModal/RegisterModal.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { userLogout } from "../../actions/UserActions.js";
import Button from "../Button/Button.jsx";
import Icon from "../Icon/Icon.jsx";
import { ch, Check } from "../../util/Checks.js";

const NavLink = ({name, to}) => (
    <span className={styles.navlink}>
        <Link to={to}>{name}</Link>
    </span>
);

class NavBar extends Component {
    constructor (props, {store}) {
        super(props);

        this.state = {
            isLoginModalOpen: false,
            isRegisterModalOpen: false,
            isOpen: false
        };
        this.store = store;
    }

    toggleModalLogin = () => {
        this.setState({isLoginModalOpen: !this.state.isLoginModalOpen})
    };

    toggleModalRegister = () => {
        this.setState({isRegisterModalOpen: !this.state.isRegisterModalOpen})
    };

    toggleIsOpen = () => {
        this.setState({isOpen: !this.state.isOpen})
    };

    render () {
        const {isLoginModalOpen, isRegisterModalOpen, isOpen} = this.state;
        const {loggedIn, user} = this.props;
        if(isOpen){
            return (
                <div className={styles.nav}>
                    <LoginModal isOpen={isLoginModalOpen} toggleModal={() => this.toggleModalLogin()}/>
                    <RegisterModal isOpen={isRegisterModalOpen} toggleModal={() => this.toggleModalRegister()}/>
                    <div className={styles.logo}>
                        <Link to="/">
                            Excellent Taste
                        </Link>
                        <Button onClick={() => this.toggleIsOpen()} iconOnly={true} icon={<Icon type="bars"/>}/>
                    </div>
                    <div className={styles.search}>
                        { ch(user.roles) && user.roles.length && user.roles.filter(p => p.name === "Admin").length >= 1 && <NavLink name="Configuratie" to="/Admin"/>}
                        { loggedIn && <NavLink name="Reserveringen" to="/Reservations"/>}
                        { loggedIn && <NavLink name="Bestellingen" to="/Orders"/>}
                        { loggedIn && <NavLink name="Klanten" to="/Customers"/>}
                    </div>
                    <div className={styles.user}>
                        {
                            !loggedIn ?
                                <div className={styles.profile}>
                                    <Button block={true} onClick={() => this.toggleModalLogin()} className={styles.logbutton}>Inloggen</Button>
                                </div>

                                :
                                <div className={styles.profile}>
                                    Ingelogd als: {user.name}
                                    <Button block={true} onClick={() => this.store.dispatch(userLogout())} className={styles.logbutton}>Uitloggen</Button>
                                </div>
                        }
                    </div>
                </div>
            )
        }else{
            return(
                <Button onClick={() => this.toggleIsOpen()} iconOnly={true} icon={<Icon type="bars"/>}/>
            )
        }

    }
}

NavBar.contextTypes = {
    store: PropTypes.object
};

NavBar.propTypes = {

};

const mapStateToProps = function (state) {
    return {
        loggedIn: state.authentication.loggedIn,
        user: state.authentication.user
    };
};

export default withRouter(connect(mapStateToProps)(NavBar));
