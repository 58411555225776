import React from "react";

const SvgListIcon = props => (
  <svg
    viewBox="0 0 60 60"
    width={props.width || "1em"}
    height={props.height || "1em"}
  >
    <g fill="#EDUWARE_COLOR_PLACEHOLDER">
      <path d="M23.429 17H47a1 1 0 1 0 0-2H23.429a1 1 0 1 0 0 2zM23.429 32H47a1 1 0 1 0 0-2H23.429a1 1 0 1 0 0 2zM23.429 47H47a1 1 0 1 0 0-2H23.429a1 1 0 1 0 0 2z" />
      <path d="M59 0H1a1 1 0 0 0-1 1v58a1 1 0 0 0 1 1h58a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm-1 58H2V2h56v56z" />
      <path d="M12.501 18.474l2.428-1.277 2.428 1.277-.463-2.704 1.964-1.916-2.715-.394L14.929 11l-1.214 2.46-2.715.394 1.965 1.916zM12.501 33.557l2.428-1.277 2.428 1.277-.463-2.704 1.964-1.915-2.715-.395-1.214-2.46-1.214 2.46-2.715.395 1.965 1.915zM12.501 49l2.428-1.277L17.357 49l-.463-2.704 1.964-1.915-2.715-.395-1.214-2.46-1.214 2.46-2.715.395 1.965 1.915z" />
    </g>
  </svg>
);

export default SvgListIcon;
