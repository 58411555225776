import axios from "axios/index";
import { CONFIG_POST } from "./AxiosConfig";

export default class api {
    static call = (url, queryObject) => {
        const config = Object.assign({}, queryObject, CONFIG_POST, {
            url: 'https://excellent-taste.maxkrul.nl/_api/'+url,
        });
        return new Promise((resolve, reject) => {
            axios(config)
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
        });
    }
}
