import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormInput.module.scss';
import InputField from "../InputField/InputField.jsx";

const FormInput = ({label, required, ...props }) => (
    <div className={styles.container}>
        <label className={styles.label}>{label}</label>
        <InputField {...props} required={required}/>
    </div>

);

FormInput.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool
};

export default FormInput