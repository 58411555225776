import React from "react";

const SvgCalendarIcon = props => (
  <svg
    viewBox="0 0 512 512"
    width={props.width || "1em"}
    height={props.height || "1em"}
  >
    <path
      d="M452 40h-24V0h-40v40H124V0H84v40H60C26.916 40 0 66.916 0 100v352c0 33.084 26.916 60 60 60h392c33.084 0 60-26.916 60-60V100c0-33.084-26.916-60-60-60zm20 412c0 11.028-8.972 20-20 20H60c-11.028 0-20-8.972-20-20V188h432v264zm0-304H40v-48c0-11.028 8.972-20 20-20h24v40h40V80h264v40h40V80h24c11.028 0 20 8.972 20 20v48z"
      fill="#EDUWARE_COLOR_PLACEHOLDER"
    />
    <path
      fill="#EDUWARE_COLOR_PLACEHOLDER"
      d="M76 230h40v40H76zM156 230h40v40h-40zM236 230h40v40h-40zM316 230h40v40h-40zM396 230h40v40h-40zM76 310h40v40H76zM156 310h40v40h-40zM236 310h40v40h-40zM316 310h40v40h-40zM76 390h40v40H76zM156 390h40v40h-40zM236 390h40v40h-40zM316 390h40v40h-40zM396 310h40v40h-40z"
    />
  </svg>
);

export default SvgCalendarIcon;
