export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';

export const userLoginSuccess = (user) => {
    return {
        type: USER_LOGIN_SUCCESS,
        user: user,
        loggedIn: true
    }
};

export const USER_LOGOUT = 'USER_LOGOUT';

export const userLogout = () => {
    return {
        type: USER_LOGOUT
    }
};