/* eslint-disable eqeqeq */
import UUID from  "uuid-js";

export const Check = {
    isObject,
    isUUID,
    isBase64
};

export function ch(check, mode = false) {
    if(mode){
        if(mode === "f"){
            return ![null, undefined, "", false, "false"].includes(check);
        }else if(mode === "z"){
            return ![null, undefined, 0].includes(check);
        }
    }else{
        return ![null, undefined, ""].includes(check);
    }
}

function isObject(obj) {
    return obj === Object(obj);
}

function isUUID(strUUID) {
    const input = UUID.fromURN(strUUID);
    return (isObject(input) && (input.hex && input.hex === strUUID));
}

function isBase64(str) {
    try {
        // noinspection EqualityComparisonWithCoercionJS
        return btoa(atob(str)) == str;
    } catch (err) {
        return false;
    }
}