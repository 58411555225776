import React from "react";

const SvgRightArrowIcon = props => (
  <svg
    viewBox="0 0 480 480"
    width={props.width || "1em"}
    height={props.height || "1em"}
  >
    <path
      d="M480 240c0 132.547-107.453 240-240 240S0 372.547 0 240 107.453 0 240 0s240 107.453 240 240zm0 0"
      fill="#EDUWARE_COLOR_PLACEHOLDER"
    />
    <path
      d="M193.602 414.398a15.997 15.997 0 0 1-11.313-27.309L329.375 240 182.305 92.93a16.003 16.003 0 0 1 7.062-26.989 16.003 16.003 0 0 1 15.563 4.364l158.398 158.398c6.246 6.25 6.246 16.375 0 22.625L204.93 409.727c-3.008 3-7.082 4.68-11.328 4.671zm0 0"
      fill="#EDUWARE_COLOR_PLACEHOLDER_SECONDARY"
    />
  </svg>
);

export default SvgRightArrowIcon;
