import React from "react";

const SvgDownloadIcon = props => (
  <svg
    viewBox="0 0 471.2 471.2"
    width={props.width || "1em"}
    height={props.height || "1em"}
  >
    <path
      d="M457.7 230.15c-7.5 0-13.5 6-13.5 13.5v122.8c0 33.4-27.2 60.5-60.5 60.5H87.5c-33.4 0-60.5-27.2-60.5-60.5v-124.8c0-7.5-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v124.8c0 48.3 39.3 87.5 87.5 87.5h296.2c48.3 0 87.5-39.3 87.5-87.5v-122.8c0-7.4-6-13.5-13.5-13.5z"
      data-original="#000000"
      className="download-icon_svg__active-path"
      data-old_color="#000000"
      fill="#EDUWARE_COLOR_PLACEHOLDER"
    />
    <path
      d="M226.1 346.75c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4l85.8-85.8c5.3-5.3 5.3-13.8 0-19.1-5.3-5.3-13.8-5.3-19.1 0l-62.7 62.8V30.75c0-7.5-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v273.9l-62.8-62.8c-5.3-5.3-13.8-5.3-19.1 0-5.3 5.3-5.3 13.8 0 19.1l85.9 85.8z"
      data-original="#000000"
      className="download-icon_svg__active-path"
      data-old_color="#000000"
      fill="#EDUWARE_COLOR_PLACEHOLDER"
    />
  </svg>
);

export default SvgDownloadIcon;
