import React from "react";

const SvgLeftArrowIcon = props => (
  <svg
    viewBox="0 0 480 480"
    width={props.width || "1em"}
    height={props.height || "1em"}
  >
    <path
      d="M480 240c0 132.547-107.453 240-240 240S0 372.547 0 240 107.453 0 240 0s240 107.453 240 240zm0 0"
      fill="#EDUWARE_COLOR_PLACEHOLDER"
    />
    <path
      d="M286.398 414.398a15.986 15.986 0 0 1-11.308-4.687L116.687 251.312c-6.246-6.25-6.246-16.375 0-22.625L275.09 70.29A16 16 0 1 1 297.71 92.91L150.625 240l147.07 147.07a16 16 0 0 1-11.297 27.328zm0 0"
      fill="#EDUWARE_COLOR_PLACEHOLDER_SECONDARY"
    />
  </svg>
);

export default SvgLeftArrowIcon;
