import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputField.module.scss';

const InputField = ({type, required,  ...props }) => (
    <input type={type} className={styles.input} required={required} {...props} />
);

InputField.defaultProps = {
    type: "text",
    required: true
};

InputField.propTypes = {
    type: PropTypes.string,
    required: PropTypes.bool
};

export default InputField