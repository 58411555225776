import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';
import ReactModal from 'react-modal';

class Modal extends Component {
    constructor (props, {store}) {
        super(props);

        this.state = {
            isOpen: false
        };
        this.store = store;
    }

    onModalClose = () => {
        this.setState({isOpen: false})
    };

    open = () => {
        this.setState({isOpen: true})
    };

    close = () => {
        this.setState({isOpen: false})
    };

    render () {
        const { children, title, desc, onClose, isOpen, className, width } = this.props;
        return (
            <ReactModal
                isOpen={typeof isOpen === 'boolean' ? isOpen : this.state.isOpen}
                onRequestClose={typeof onClose === 'function' ? onClose : this.onModalClose}
                className={`${styles.modal} ${typeof className === 'string' && className}`}
                overlayClassName={styles.overlay}
                style={typeof width === 'string' ? {
                    content: {
                        width: width
                    }
                } : {}}
            >
                <div className={styles.header}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.close} onClick={typeof onClose === 'function' ? onClose : this.onModalClose}/>
                </div>
                <div className={styles.middle}>
                    {desc &&
                    <p className={styles.text}>
                        {desc}
                    </p>
                    }
                    {children}
                </div>
            </ReactModal>
        )
    }
}

Modal.contextTypes = {
    store: PropTypes.object
};

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    width: PropTypes.string
};

export default Modal;
