import React from "react";

const SvgLoadDotsIcon = props => (
  <svg
    viewBox="0 0 120 30"
    fill="#EDUWARE_COLOR_PLACEHOLDER"
    width={props.width || "1em"}
    height={props.height || "1em"}
  >
    <circle cx={15} cy={15} r={15}>
      <animate
        attributeName="fill-opacity"
        from={1}
        to={1}
        begin="0s"
        dur="0.8s"
        values="0;1;0"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx={60} cy={15} r={15} fillOpacity={0.3}>
      <animate
        attributeName="fill-opacity"
        from={0.5}
        to={0.5}
        begin="0s"
        dur="0.8s"
        values="0;1;0"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx={105} cy={15} r={15}>
      <animate
        attributeName="fill-opacity"
        from={1}
        to={1}
        begin="0s"
        dur="0.8s"
        values="0;1;0"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export default SvgLoadDotsIcon;
