import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from "../Modal/Modal.jsx";
import FormInput from "../FormInput/FormInput.jsx";
import styles from './LoginModal.module.scss';
import Button from "../Button/Button.jsx";
import api from "../../util/api.js";
import { userLoginSuccess } from "../../actions/UserActions.js";

class LoginModal extends Component {
    constructor (props, {store}) {
        super(props);

        this.state = {
            email: "",
            password: "",
            error: ""
        };
        this.store = store;
    }

    handleInputChange = (event) => {
        var target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        var name = target.name;

        this.setState({
            [name]: value
        });
    };

    setError = (msg) => {
        this.setState({error: msg})
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {password, email} = this.state;

        api.call("user_login.php", {
            data: {
                password,
                email
            }
        })
        .then((result) => {
            if(result.data.ok === true){
                const { name, email, roles } = result.data.user;
                this.store.dispatch(userLoginSuccess({name, email, hashedPassword: password, roles}));
                this.props.toggleModal()
            }else{
                this.setError(result.data.error)
            }
        })
    };

    render () {
        const { error } = this.state;
        return (
            <Modal title="Login"
                   desc="Hier kun je inloggen"
                   isOpen={this.props.isOpen}
                   onClose={this.props.toggleModal}
            >
                <form className={styles.form} onSubmit={(e) => this.handleSubmit(e)}>
                    <FormInput onChange={(e) => this.handleInputChange(e)} type="email" name="email" label="Email: " placeholder="Vul in..."/>
                    <FormInput onChange={(e) => this.handleInputChange(e)} type="password" name="password" label="Wachtwoord: " placeholder="Vul in..."/>
                    <div>
                        <span className={styles.error}>{error}</span>
                    </div>
                    <div className={styles.footer}>
                        <Button buttontype="submit">Inloggen</Button>
                    </div>
                </form>
            </Modal>
        )
    }
}

LoginModal.contextTypes = {
    store: PropTypes.object
};

LoginModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired
};

export default LoginModal;
