import React from "react";

const SvgStarActiveIcon = props => (
  <svg
    viewBox="0 0 55.867 55.867"
    width={props.width || "1em"}
    height={props.height || "1em"}
  >
    <path
      d="M55.818 21.578a1.002 1.002 0 0 0-.808-.681l-18.09-2.629-8.09-16.392a.998.998 0 0 0-1.792 0l-8.091 16.393-18.09 2.629a1.002 1.002 0 0 0-.555 1.705l13.091 12.76-3.091 18.018c-.064.375.09.754.397.978a.992.992 0 0 0 1.053.076l16.182-8.506 16.18 8.506a1 1 0 0 0 1.451-1.054l-3.09-18.017 13.091-12.761c.272-.267.37-.664.252-1.025z"
      fill="#EDUWARE_COLOR_PLACEHOLDER"
    />
  </svg>
);

export default SvgStarActiveIcon;
