import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from "../Modal/Modal.jsx";
import styles from './RegisterModal.module.scss';
import FormInput from "../FormInput/FormInput.jsx";
import Button from "../Button/Button.jsx";
import api from "./../../util/api.js";
import { userLoginSuccess } from "../../actions/UserActions.js";

class RegisterModal extends Component {
    constructor (props, {store}) {
        super(props);

        this.state = {
            desc: "Hier kun je medewerkers registreren",
            username: "",
            password: "",
            email: "",
            passwordverify: "",
            error: ""
        };
        this.store = store;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {username, password, email, passwordverify} = this.state;

        if(password !== passwordverify){
            this.setError("Wachtwoorden verschillen");
            return false;
        }

        api.call("user_register.php", {
            data: {
                name: username,
                password,
                email
            }
        })
        .then((result) => {
            if(result.data.ok === true){
                this.store.dispatch(userLoginSuccess({email, hashedPassword: password, name: username}));
                this.props.toggleModal()
            }else{
                this.setError(result.data.error)
            }
        })
    };

    setError = (msg) => {
        this.setState({error: msg})
    };

    handleInputChange = (event) => {
        var target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        var name = target.name;

        this.setState({
            [name]: value
        });
    };

    render () {
        const employee = (
            <>
                <FormInput onChange={(e) => this.handleInputChange(e)} name="username" label="Voornaam: " placeholder="Vul in..."/>
                {/*<FormInput onChange={(e) => this.handleInputChange(e)} label="Achternaam: " placeholder="Vul in..."/>*/}
                <FormInput onChange={(e) => this.handleInputChange(e)} name="email" label="Email: " type="email" placeholder="Vul in..."/>
                <FormInput onChange={(e) => this.handleInputChange(e)} type="password" name="password" label="Wachtwoord: " placeholder="Vul in..."/>
                <FormInput onChange={(e) => this.handleInputChange(e)} type="password" name="passwordverify" label="Herhaal Wachtwoord: " placeholder="Vul in..."/>
            </>
        );
        const {desc, error} = this.state;
        return (
            <Modal
                title="Registratie"
                desc={desc}
                isOpen={this.props.isOpen}
                onClose={this.props.toggleModal}
            >
                <form className={styles.form} onSubmit={(e) => this.handleSubmit(e)}>
                    {employee}
                    <div>
                        <span className={styles.error}>{error}</span>
                    </div>
                    <div className={styles.footer}>
                        <Button buttontype="submit">Registeren</Button>
                    </div>
                </form>
            </Modal>
        )
    }
}

RegisterModal.contextTypes = {
    store: PropTypes.object
};

RegisterModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired
};

export default RegisterModal;
