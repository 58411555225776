import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import styles from "./Button.module.scss";
import Icon from "../Icon/Icon.jsx";
import { createSkeletonElement } from '@trainline/react-skeletor';
import { ch } from "../../util/Checks.js";
import { Link } from "react-router-dom";

const Button = ({ className, disabled, href, icon, loading, shape, severity, size, type, onClick, block, buttontype, children, iconOnly, skeleton, to, ...props }) => {
    let buttonClass = classNames(className, {
        [styles.btn]: !iconOnly,
        [styles.disabled]: disabled,
        [styles.loading]: loading,
        [styles[`shape-${shape}`]]: shape,
        [styles[`size-${size}`]]: size,
        //[styles[`icon-${icon}`]]: icon,
        [styles[`type-${type}`]]: type,
        [styles.block]: block,
        [styles.iconOnly]: iconOnly,
        [styles[`iconOnly-${size}`]]: iconOnly,
        [styles[`severity-${severity}`]]: severity
    });
    const TYPE = ch(to) ? Link : "button";
    const BUTTON = skeleton ? createSkeletonElement(TYPE) : TYPE;
    return (
        <BUTTON {...props} disabled={disabled} className={buttonClass} onClick={onClick} to={to} type={buttontype}>
            {icon && icon}
            {loading && <Icon type="load-circle" fill="black"/>}
            <span>{children}</span>
        </BUTTON>
    );
};

Button.propTypes = {
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    loading: PropTypes.bool,
    shape: PropTypes.oneOf(['circle']),
    size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'text']),
    type: PropTypes.oneOf(['primary', 'secondary', 'dashed']),
    severity: PropTypes.oneOf(["danger", "warning"]),
    onClick: PropTypes.func,
    block: PropTypes.bool,
    iconOnly: PropTypes.bool,
    skeleton: PropTypes.bool,
    to: PropTypes.string,
    buttontype: PropTypes.string,
    children: PropTypes.node//.isRequired
};

Button.defaultProps = {
    disabled: false,
    //icon: "",
    loading: false,
    //shape: "",
    size: "medium",
    type: "primary",
    block: false,
    iconOnly: false,
    skeleton: false,
    //severity: "none",
    buttontype: "button"
};

export default Button