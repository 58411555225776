import { USER_LOGIN_SUCCESS, USER_LOGOUT} from '../actions/UserActions'

const initialState = {
    loggedIn: false,
    user: {
        'email': "",
        'hashedPassword': "",
        'name': "",
    }
};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case USER_LOGIN_SUCCESS: {
            return Object.assign({}, state, {
                loggedIn: action.loggedIn,
                user: action.user
            })
        }
        case USER_LOGOUT: {
            return Object.assign({}, state, initialState)
        }
        default:
            return state
    }
}
