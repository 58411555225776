import { combineReducers } from 'redux';
import { authentication } from "./AuthReducer.js";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['authentication']
};

const rootReducer = combineReducers({
    authentication,
});

export default persistReducer(persistConfig, rootReducer);