import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from "redux-persist";
import { createStore, applyMiddleware, compose} from 'redux';
import { PersistGate } from 'redux-persist/lib/integration/react'
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux'
import persistedReducer from './reducers'

const initialState = {
    authentication: {
        loggedIn: false,
        user: {
            'email': "",
            'hashedPassword': "",
            'name': "",

        }
    }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(thunkMiddleware)));
const persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
