import React from "react";

const SvgBellCircleIcon = props => (
  <svg
    viewBox="0 0 300 300"
    width={props.width || "1em"}
    height={props.height || "1em"}
  >
    <path
      d="M149.996 0C67.157 0 .001 67.161.001 149.997S67.157 300 149.996 300s150.003-67.163 150.003-150.003S232.835 0 149.996 0zm.003 232.951c-10.766 0-19.499-8.725-19.499-19.499h38.995c.002 10.774-8.73 19.499-19.496 19.499zm65.89-39.051h-.005v-.001c0 7.21-5.843 7.685-13.048 7.685H97.16c-7.208 0-13.046-.475-13.046-7.685v-1.242c0-5.185 3.045-9.625 7.42-11.731l4.142-35.753c0-26.174 18.51-48.02 43.152-53.174v-13.88c0-6.17 5.003-11.173 11.176-11.173 6.17 0 11.173 5.003 11.173 11.173V92c24.642 5.153 43.152 26.997 43.152 53.174l4.142 35.758c4.375 2.109 7.418 6.541 7.418 11.726v1.242z"
      fill="#EDUWARE_COLOR_PLACEHOLDER"
    />
  </svg>
);

export default SvgBellCircleIcon;
