import React from "react";

const SvgDownArrowIcon = props => (
  <svg
    viewBox="0 0 255 255"
    width={props.width || "1em"}
    height={props.height || "1em"}
  >
    <path
      fill="#EDUWARE_COLOR_PLACEHOLDER"
      d="M0 63.75l127.5 127.5L255 63.75z"
    />
  </svg>
);

export default SvgDownArrowIcon;
