import React from 'react';
import PropTypes from 'prop-types';
import BellCircleIcon from './../../assets/Icons/BellCircleIcon.js';
import CheckMarkCircleIcon from './../../assets/Icons/CheckMarkCircleIcon.js';
import CommentIcon from './../../assets/Icons/CommentIcon.js';
import DownArrowIcon from './../../assets/Icons/DownArrowIcon.js';
import ListIcon from './../../assets/Icons/ListIcon.js';
import LoadCircleIcon from './../../assets/Icons/LoadCircleIcon.js';
import LoadDotsIcon from './../../assets/Icons/LoadDotsIcon.js';
import PlayCircleIcon from './../../assets/Icons/PlayCircleIcon.js';
import PlusCircleIcon from './../../assets/Icons/PlusCircleIcon.js';
import QuestionMarkCircleIcon from './../../assets/Icons/QuestionMarkCircleIcon.js';
import SearchIcon from './../../assets/Icons/SearchIcon.js';
import StarActiveIcon from './../../assets/Icons/StarActiveIcon.js';
import StarInactiveIcon from './../../assets/Icons/StarInactiveIcon.js';
import XIcon from './../../assets/Icons/XIcon.js';
import RightArrowIcon from './../../assets/Icons/RightArrowIcon.js'
import LeftArrowIcon from './../../assets/Icons/LeftArrowIcon.js'
import DownloadIcon from './../../assets/Icons/DownloadIcon.js';
import CalendarIcon from './../../assets/Icons/CalendarIcon.js';
import BarsIcon from './../../assets/Icons/BarsIcon.js';

const Icon = ({ type, fill, fillSecondary, ...props }) => {
    //TODO: Implementation needs to be more DRY
    let fillColor;
    switch(fill){
        case "primary":
            fillColor = "#9ecdd6";
            break;
        case "secondary":
            fillColor = "#496e74";
            break;
        case "black":
            fillColor = "#000000";
            break;
        default:
            fillColor = fill;
    }

    let fillColorSecondary;
    switch(fillSecondary){
        case "primary":
            fillColorSecondary = "#9ecdd6";
            break;
        case "secondary":
            fillColorSecondary = "#496e74";
            break;
        case "black":
            fillColorSecondary = "#000000";
            break;
        default:
            fillColorSecondary = fillSecondary;
    }

    switch(type) {
        case "search":
            return <SearchIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "x":
            return <XIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "check-circle":
            return <CheckMarkCircleIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "star-inactive":
            return <StarInactiveIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "star-active":
            return <StarActiveIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "plus-circle":
            return <PlusCircleIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "play-circle":
            return <PlayCircleIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "bell-circle":
            return <BellCircleIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "load-dots":
            return <LoadDotsIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "load-circle":
            return <LoadCircleIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "list":
            return <ListIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "question-mark-circle":
            return <QuestionMarkCircleIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "down-arrow":
            return <DownArrowIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "comment":
            return <CommentIcon fill={fillColor} {...props} />;
        case "right-arrow":
            return <RightArrowIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "left-arrow":
            return <LeftArrowIcon fill={fillColor} fillSecondary={fillColorSecondary}{...props} />;
        case "download":
            return <DownloadIcon fill={fillColor} {...props} />;
        case "calendar":
            return <CalendarIcon fill={fillColor} {...props} />;
        case "bars":
            return <BarsIcon fill={fillColor} {...props} />;
        default:
            return <div />;
    }
};

Icon.defaultProps = {
    fill: "#9ecdd6",
    fillSecondary: "#496e74"
};

Icon.propTypes = {
    type: PropTypes.string.isRequired,
    fill: PropTypes.string,
    fillSecondary: PropTypes.string
};

export default Icon