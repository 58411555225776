import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import Loadable from 'react-loadable';
import NavBar from './components/NavBar/NavBar.jsx';
import styles from './App.module.scss';
import { withRouter } from "react-router";
import Icon from "./components/Icon/Icon.jsx";
export const Loading = () => <div style={{width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}><Icon type="load-circle"/></div>;

const HomePage = Loadable({
    loader: () => import('./pages/HomePage.jsx'),
    loading: Loading,
});

const ReservationPage = Loadable({
    loader: () => import('./pages/ReservationPage.jsx'),
    loading: Loading,
});

const CustomerPage = Loadable({
    loader: () => import('./pages/CustomerPage.jsx'),
    loading: Loading,
});

const OrderPage = Loadable({
    loader: () => import('./pages/OrderPage.jsx'),
    loading: Loading,
});

const NotFoundPage = Loadable({
    loader: () => import('./pages/NotFoundPage.jsx'),
    loading: Loading,
});

const AdminPage = Loadable({
    loader: () => import('./pages/AdminPage.jsx'),
    loading: Loading,
});

class App extends Component {
  render() {
    return (
      <div className="app">
          <NavBar/>
          <div  className={styles.app}>
              <div className={styles.inside}>
                  <Switch>
                      <Route exact path='/' component={HomePage}/>
                      <Route path='/Admin' component={AdminPage}/>
                      <Route path='/Reservations' component={ReservationPage}/>
                      <Route path='/Customers' component={CustomerPage}/>
                      <Route path='/Orders' component={OrderPage}/>
                      <Route component={NotFoundPage}/>
                  </Switch>
              </div>
          </div>
      </div>
    );
  }
}

export default withRouter(App);
